<template>
  <div class="calc">

    <div class="head-links">
      <router-link class="back-links" to="/pediatrics">Головна</router-link>
      <span>/</span>
      <router-link class="now-page" to="/calculator">Калькулятор</router-link>
    </div>

    <div class="content-wrapper">

      <div class="filter-block">
        <p class="h2-title">Калькулятор</p>

        <div class="drop-item1" v-bind:class="[dropList1 !== false ? activeClass: '', ``]">
          <p @click="openDropListNeed()" class="visible-item">
            <svg class="icon" width="33" height="28" viewBox="0 0 33 28" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <line x1="2.7" y1="4" x2="2.7" y2="24" stroke="#1E3B89" stroke-width="1.4"/>
              <line x1="22.7" y1="4" x2="22.7" y2="10" stroke="#1E3B89" stroke-width="1.4"/>
              <line x1="22.7" y1="12" x2="22.7" y2="24" stroke="#1E3B89" stroke-width="1.4"/>
              <mask id="path-4-inside-1" fill="white">
                <rect y="24" width="25" height="4" rx="1"/>
              </mask>
              <rect y="24" width="25" height="4" rx="1" stroke="#1E3B89" stroke-width="2.6"
                    mask="url(#path-4-inside-1)"/>
              <mask id="path-5-inside-2" fill="white">
                <rect width="25" height="4" rx="1"/>
              </mask>
              <rect width="25" height="4" rx="1" stroke="#1E3B89" stroke-width="2.6"
                    mask="url(#path-5-inside-2)"/>
              <path
                  d="M13.4648 9.71836H12.8648V10.3184V15.9498C12.8648 16.7149 13.4864 17.3364 14.2515 17.3364H18.5302C19.2951 17.3364 19.9166 16.7148 19.9166 15.9498V11.9252H30.4536C31.0624 11.9252 31.5569 11.4308 31.5569 10.8219C31.5569 10.213 31.0626 9.71836 30.4536 9.71836H27.6537H13.4648Z"
                  stroke="#1E3B89" stroke-width="1.2"/>
            </svg>
            <span>{{ filter1Chose }}</span>

            <svg class="arrow" width="12" height="7" viewBox="0 0 12 7" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M10.7783 1.24219L6.26562 5.77767" stroke="#00ACE6" stroke-width="2"
                    stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M6.26608 5.77767L1.75684 1.24219" stroke="#00ACE6" stroke-width="2"
                    stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

          </p>

          <div class="hide-item">
            <p v-bind:class="[filter1Chose === key.name ? activeClass: '', ``]"
               v-for="(key, value) in filterItemDrop1" v-html="key.name"
               @click="choseFilter1(key.type, key.name)"></p>
          </div>

        </div>

        <div class="drop-item2" v-bind:class="[dropList2 !== false ? activeClass: '', ``]">
          <p @click="openDropListAge()" class="visible-item">
            <svg class="icon" width="34" height="27" viewBox="0 0 34 27" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M29.1586 15.8381L29.1585 15.8383C27.9975 21.0915 23.0151 24.9128 17.3056 24.9128C11.5962 24.9128 6.6149 21.0916 5.45376 15.8385C5.39423 15.565 5.15179 15.3715 4.87382 15.3715H4.54969C3.19272 15.3715 2.08822 14.2669 2.08822 12.911C2.08822 11.555 3.19277 10.4496 4.54969 10.4496H5.2029C5.4581 10.4496 5.68513 10.287 5.76609 10.0445C7.36714 5.28932 12.0031 2.08822 17.3056 2.08822C22.6082 2.08822 27.2432 5.28931 28.8452 10.0445L28.8452 10.0446C28.9271 10.2866 29.1528 10.4496 29.4084 10.4496H30.0626C31.4195 10.4496 32.5231 11.554 32.5231 12.911C32.5231 14.268 31.4195 15.3715 30.0626 15.3715H29.7384C29.4599 15.3715 29.2191 15.5665 29.1586 15.8381ZM0.9 12.911C0.9 14.875 2.45941 16.4814 4.40558 16.5566C5.86949 22.1176 11.2172 26.1 17.3056 26.1C23.394 26.1 28.7428 22.1176 30.2086 16.5566C32.1539 16.4814 33.7132 14.875 33.7132 12.911C33.7132 10.8981 32.0755 9.26133 30.0646 9.26133H29.8286C27.9415 4.23972 22.963 0.9 17.3066 0.9C11.6502 0.9 6.67171 4.23972 4.7846 9.26133H4.54969C2.53678 9.26133 0.9 10.8991 0.9 12.911Z"
                  fill="#1E3B89" stroke="#1E3B89" stroke-width="0.2"/>
              <path
                  d="M21.4467 16.1106C22.1612 16.1106 22.7405 15.5313 22.7405 14.8168C22.7405 14.1023 22.1612 13.523 21.4467 13.523C20.7322 13.523 20.1529 14.1023 20.1529 14.8168C20.1529 15.5313 20.7322 16.1106 21.4467 16.1106Z"
                  fill="#1E3B89" stroke="#1E3B89" stroke-width="0.2"/>
              <path
                  d="M13.6205 16.1106C14.3351 16.1106 14.9143 15.5313 14.9143 14.8168C14.9143 14.1023 14.3351 13.523 13.6205 13.523C12.906 13.523 12.3268 14.1023 12.3268 14.8168C12.3268 15.5313 12.906 16.1106 13.6205 16.1106Z"
                  fill="#1E3B89" stroke="#1E3B89" stroke-width="0.2"/>
              <path
                  d="M13.5654 20.2467L13.5654 20.2467L13.566 20.247L13.7605 20.3665C13.7605 20.3665 13.7605 20.3665 13.7606 20.3665C14.8526 21.0394 16.0774 21.3755 17.3044 21.3755C18.5304 21.3755 19.7571 21.0384 20.8511 20.3656L20.8512 20.3655L21.0449 20.2459L21.0451 20.2458C21.3229 20.0737 21.411 19.7079 21.2386 19.4284C21.066 19.1487 20.699 19.0649 20.4214 19.2345L20.4214 19.2345L20.4209 19.2349L20.2285 19.3543C20.2284 19.3543 20.2284 19.3543 20.2283 19.3543C18.4224 20.466 16.1842 20.465 14.3833 19.3553L14.1896 19.2357L14.1896 19.2357L14.1893 19.2355C13.9115 19.0658 13.5458 19.1499 13.3723 19.4291L13.3723 19.4291L13.3721 19.4294C13.1995 19.7091 13.288 20.0736 13.5654 20.2467Z"
                  fill="#1E3B89" stroke="#1E3B89" stroke-width="0.2"/>
            </svg>

            <span>{{ filter2Chose }}</span>

            <svg class="arrow" width="12" height="7" viewBox="0 0 12 7" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M10.7783 1.24219L6.26562 5.77767" stroke="#00ACE6" stroke-width="2"
                    stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M6.26608 5.77767L1.75684 1.24219" stroke="#00ACE6" stroke-width="2"
                    stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

          </p>

          <div class="hide-item ">
            <p v-bind:class="[filter2Chose === key.name ? activeClass: '', ``]"
               v-for="(key, value) in filterItemDrop2" v-html="key.name"
               @click="choseFilter2(key.type, key.name)"></p>

          </div>
        </div>

      </div>

      <div class="production-wrapper">

        <div class="count-prod">
          <p>{{ allProd }} <span v-html="this.declOfNum(allProd, ['продукт', 'продукти', 'продуктів'])"></span></p>
        </div>

        <div v-if="key.show === true && key.hide === false"
             class="item-block" v-for="(key, value) in allProdItems">
          <p class="h2-title" v-html="key.name"></p>
          <p class="type" v-html="key.filters.name"></p>
          <img :src="require(`../assets/img/pack/${key.img}`)" alt="">

          <div v-if="key.btn1" @click="goToNextStage(key.id, key.to)" :class="`${key.gtm} calc-btn`">
            <p :class="`${key.gtm}`">розрахувати добову норму</p>
          </div>

          <div v-if="key.btn2"
               @click="goToNextStageOverview(key.id, key.to)"
               :class="`${key.gtm} calc-btn sampling-btn`"
          >
            <p :class="`${key.gtm}`">опис продукту</p>
          </div>

          <router-link
              v-if="!([6, 7, 5, 8, 9, 10, 16].includes(key.id)) && key.btn3"
              to="/pediatrics/sampling"
              class="calc-btn sampling-btn"
          >
            <p>Замовити зразки</p>
          </router-link>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'calc',
  data: () => ({

    activeClass: "active",
    allProd: 0,

    dropList1: false,
    dropList2: false,
    error: null,
    allProdItems: [

      {
        id: "base-1-powder-Profutura-Duobiotic",
        name: "Суміш молочна суха Nutrilon Profutura 1",
        filters: {
          name: "Здорові діти",
          type: "healthy",
          age: ["0-6"],

        },
        img: "31.png",
        show: true,
        gtm: "btn-calc-day-norm-nutrilon-dairy",
        hide: false,
        to: "calc1",
        btn1: true,
        btn2: true,
        btn3: false,
      },

      {
        id: "base-2-powder-Profutura-Duobiotic",
        name: "Суміш молочна суха Nutrilon Profutura 2",
        filters: {
          name: "Здорові діти",
          type: "healthy",
          age: ["6-12"],

        },
        img: "32.png",
        show: true,
        gtm: "btn-calc-day-norm-nutrilon-dairy",
        hide: false,
        to: "calc1",
        btn1: true,
        btn2: true,
        btn3: false,
      },

      {
        id: "base-3-powder-Profutura-Duobiotic",
        name: "Суміш молочна суха Nutrilon Profutura 3",
        filters: {
          name: "Здорові діти",
          type: "healthy",
          age: ["12-24"],

        },
        img: "33.png",
        show: true,
        gtm: "btn-calc-day-norm-nutrilon-dairy",
        hide: false,
        to: "calc1",
        btn1: false,
        btn2: true,
        btn3: false,
      },

      {
        id: 16,
        name: "Суміш суха амінокислотна Neocate",
        filters: {
          name: "Харчова алергія, Проблеми з травленням",
          type: "digestion allergy",
          age: ["0-6", "6-12", "12+", "18+", "36+"],

        },
        img: "20.png",
        show: true,
        gtm: "calc-nutrlinon-neocate",
        hide: false,
        to: "calc1",

        btn1: true,
        btn2: true,
        btn3: false,
      },
      {
        id: 14,
        name: "Суміш рідка Infatrini",
        filters: {
          name: "Недостатність харчування",
          type: "lowFood",
          age: ["0-6", "6-12", "12+"],

        },
        img: "17.png",
        show: true,
        gtm: "btn-calc-day-norm-nutrilon-dairy",
        hide: false,
        to: "calc2",

        btn1: true,
        btn2: true,
        btn3: true,
      },
      {
        id: 15,
        name: "Суміш рідка Infatrini Пептісорб",
        filters: {
          name: "Проблеми з травленням, Харчова алергія, Недостатність харчування",
          type: "digestion allergy lowFood",
          age: ["0-6", "6-12", "12+"],

        },
        img: "18.png",
        show: true,
        gtm: "btn-calc-day-norm-nutrilon-dairy",
        hide: false,
        to: "calc2",
        btn1: true,
        btn2: true,
        btn3: true,
      },
      {
        id: "fortini-mf6-15-liquid",
        name: "Напій рідкий Fortini Multi Fibre",
        filters: {
          name: "Недостатність харчування, Проблеми з травленням",
          type: "lowFood digestion",
          age: ["12+", "18+", "36+"],

        },
        img: "36.png",
        show: true,
        gtm: "btn-calc-nutridrink-compact",
        hide: false,
        to: "calc2",
        btn1: true,
        btn2: true,
        btn3: true,
      },
      {
        id: "nutridrink-mfC-MCI",
        name: "Суміш рідка Nutridrink Compact Fibre",
        filters: {
          name: "Недостатність харчування",
          type: "lowFood",
          age: ["36+"],

        },
        img: "19.3.png",
        show: true,
        gtm: "btn-calc-nutridrink-compact",
        hide: false,
        to: "calc2",
        btn1: true,
        btn2: true,
        btn3: true,
      },
      {
        id: 12,
        name: "Суміш рідка Nutrini",
        filters: {
          name: "Недостатність харчування",
          type: "lowFood",
          age: ["12+", "36+", "18+"],

        },
        img: "15.png",
        show: true,
        gtm: "btn-calc-day-norm-nutrilon-dairy",
        hide: false,
        to: "calc2",
        btn1: true,
        btn2: true,
        btn3: true,
      },
      {
        id: "Nutrison-Powder",
        name: "Суміш суха Nutrison Powder",
        filters: {
          name: "Недостатність харчування, Проблеми з травленням",
          type: "lowFood digestion",
          age: ["12+", "36+", "18+"],

        },
        img: "16.png",
        show: true,
        gtm: "btn-calc-day-norm-nutrilon-dairy",
        hide: false,
        to: "calc2",
        btn1: true,
        btn2: true,
        btn3: true,
      },
      {
        id: "base-1-liquid-POF",
        name: "Суміш молочна рідка Nutrilon 1",
        filters: {
          name: "Здорові діти",
          type: "healthy",
          age: ["0-6"],

        },
        img: "28.jpg",
        show: true,
        gtm: "btn-calc-day-norm-nutrilon-dairy",
        hide: false,
        to: "calc1",
        btn1: true,
        btn2: true,
        btn3: false,
      },
      {
        id: 'base-1-powder-POF',
        name: "Суміш молочна суха Nutrilon 1",
        filters: {
          name: "Здорові діти",
          type: "healthy",
          age: ["0-6"],

        },
        img: "10.png",
        show: true,
        gtm: "btn-calc-day-norm-nutrilon-dairy",
        hide: false,
        to: "calc1",
        btn1: true,
        btn2: true,
        btn3: false,
      },
      {
        id: 'base-2-powder-POF',
        name: "Суміш молочна суха Nutrilon 2",
        filters: {
          name: "Здорові діти",
          type: "healthy",
          age: ["6-12"],

        },
        img: "11.png",
        show: true,
        gtm: "btn-calc-day-norm-nutrilon-dairy",
        hide: false,
        to: "calc1",
        btn1: true,
        btn2: true,
        btn3: false,
      },

      {
        id: 'base-3-powder-POF',
        name: "Суміш молочна суха Nutrilon 3",
        filters: {
          name: "Здорові діти",
          type: "healthy",
          age: ["12+"],
        },
        img: "21.png",
        show: true,
        gtm: "btn-calc-day-norm-nutrilon-dairy",
        hide: false,
        to: "calc1",
        btn1: false,
        btn2: true,
        btn3: false,
      },
      {
        id: "base-4-powder-POF",
        name: "Суміш молочна суха Nutrilon 4",
        filters: {
          name: "Здорові діти",
          type: "healthy",
          age: ["18+"],

        },
        img: "22.png",
        show: true,
        gtm: "btn-calc-day-norm-nutrilon-dairy",
        hide: false,
        to: "calc1",
        btn1: false,
        btn2: true,
        btn3: false,
      },

      {
        id: "sensitive-1",
        name: "Суміш суха молочна Nutrilon для чутливих малюків 1",
        filters: {
          name: "Здорові діти",
          type: "healthy",
          age: ["0-6"],

        },
        img: "29.png",
        show: true,
        gtm: "btn-calc-day-norm-nutrilon-dairy",
        hide: false,
        to: "calc1",
        btn1: true,
        btn2: true,
        btn3: false,
      },

      {
        id: "sensitive-2",
        name: "Суміш суха молочна Nutrilon для чутливих малюків 2",
        filters: {
          name: "Здорові діти",
          type: "healthy",
          age: ["6-12", "12+", "18+"],

        },
        img: "30.png",
        show: true,
        gtm: "btn-calc-day-norm-nutrilon-dairy",
        hide: false,
        to: "calc1",
        btn1: true,
        btn2: true,
        btn3: false,
      },

      {
        id: 0,
        name: "Суміш молочна суха Nutrilon Комфорт 1",
        filters: {
          name: "Проблеми з травленням",
          type: "digestion",
          age: "0-6",
        },
        show: true,
        gtm: "calc-day-norm-nutrilon-comfort",
        img: "1.png",
        to: "calc1",
        hide: false,
        btn1: true,
        btn2: true,
        btn3: true,
      },
      {
        id: 3,
        name: "Суміш молочна суха Nutrilon Комфорт 2",
        filters: {
          name: "Проблеми з травленням",
          type: "digestion",
          age: ["6-12", "12+", "18+"],

        },
        img: "4.png",
        show: true,
        gtm: "calc-day-norm-nutrilon-comfort_2",
        to: "calc1",
        hide: false,
        btn1: true,
        btn2: true,
        btn3: true,
      },
      {
        id: 1,
        name: "Суміш суха Nutrilon Пепті",
        filters: {
          name: "Харчова алергія",
          type: "allergy",
          age: ["0-6", "6-12", "12+", "18+"],
        },
        show: true,
        gtm: "calc-day-norm-nutrilon-pepti",
        to: "calc1",
        img: "2.png",
        hide: false,
        btn1: true,
        btn2: true,
        btn3: true,
      },
      // {
      //   id: 6,
      //   name: "Суміш суха Nutrilon Гіпоалергенний 1",
      //   filters: {
      //     name: "Здорові діти",
      //     type: "healthy",
      //     age: ["0-6"],
      //
      //   },
      //   img: "7.png",
      //   show: true,
      //   gtm: "btn-calc-day-norm-nutrilon-dairy",
      //   to: "calc1",
      //   hide: false,
      //   btn1: true,
      //   btn2: true,
      //   btn3: false,
      // },
      // {
      //   id: 7,
      //   name: "Суміш суха Nutrilon Гіпоалергенний 2",
      //   filters: {
      //     name: "Здорові діти",
      //     type: "healthy",
      //     age: ["6-12", "12+", "18+"],
      //
      //   },
      //   img: "8.png",
      //   show: true,
      //   gtm: "btn-calc-day-norm-nutrilon-dairy",
      //   to: "calc1",
      //   hide: false,
      //   btn1: true,
      //   btn2: true,
      //   btn3: false,
      // },
      {
        id: 2,
        name: "Суміш суха Nutrilon Безлактозний",
        filters: {
          name: "Проблеми з травленням",
          type: "digestion",
          age: ["0-6", "6-12", "12+", "18+"],
        },
        img: "3.png",
        show: true,
        gtm: "calc-day-norm-nutrilon-nonlactose",
        to: "calc1",
        hide: false,
        btn1: true,
        btn2: true,
        btn3: true,
      },
      {
        id: 11,
        name: "Суміш суха Nutrilon Антирефлюкс",
        filters: {
          name: "Проблеми з травленням",
          type: "digestion",
          age: ["0-6", "6-12", "12+", "18+"],

        },
        img: "14.png",
        show: true,
        gtm: "btn-calc-day-norm-nutrilon-dairy",
        to: "calc1",
        hide: false,
        btn1: true,
        btn2: true,
        btn3: true,
      },
      {
        id: 4,
        name: "Суміш суха Nutrilon Мальабсорбція",
        filters: {
          name: "Харчова алергія, Проблеми з травленням",
          type: "allergy digestion",
          age: ["0-6", "6-12", "12+", "18+"],

        },
        img: "5.png",
        show: true,
        gtm: "calc-day-norm-nutrilon-malabsorcia",
        to: "calc1",
        hide: false,
        btn1: true,
        btn2: true,
        btn3: true,
      },
      {
        id: 5,
        name: "Суміш суха Nutrilon Кисломолочний 1",
        filters: {
          name: "Проблеми з травленням",
          type: "digestion",
          age: ["0-6"],

        },
        img: "6.png",
        show: true,
        gtm: "btn-calc-day-norm-nutrilon-dairy",
        to: "calc1",
        hide: false,
        btn1: true,
        btn2: true,
        btn3: false,
      },
      {
        id: '8',
        name: "Суміш суха Nutrilon Кисломолочний 2",
        filters: {
          name: "Проблеми з травленням",
          type: "digestion",
          age: ["6-12", "12+", "18+"],

        },
        img: "9.png",
        show: true,
        gtm: "btn-calc-day-norm-nutrilon-dairy",
        to: "calc1",
        hide: false,
        btn1: true,
        btn2: true,
        btn3: true,
      },
      {
        id: '18',
        name: "Liquigen Жирова емульсія",
        filters: {
          // name: "Проблеми з травленням, Недостатність харчування, Кетогенна дієта, Передчасно народжені",
          name: "Недостатність харчування, Проблеми з травленням, Передчасно народжені, Фармакорезистентна епілепсія, Кетогенна дієта",
          type: "lowFood digestion prematurely epi KDiet",
          age: ["0-6", "6-12", "12+", "18+", "36+"],

        },
        img: "liquigen.png",
        show: true,
        gtm: "btn-calc-day-norm-nutrilon-dairy",
        to: "calc2",
        hide: false,
        btn1: false,
        btn2: true,
        btn3: true,
      },
      {
        id: "ketocal-3-1",
        name: "Суміш суха KetoCal 3:1",
        filters: {
          name: "Епілепсія, Кетогенна дієта",
          type: "epi KDiet",
          age: ["0-6", "6-12", "12+", "18+", "36+"],

        },
        img: "27.1.png",
        show: true,
        gtm: "btn-calc-day-norm-nutrilon-dairy",
        hide: false,
        to: "calc1",
        btn1: false,
        btn2: true,
        btn3: false,
      },
      {
        id: "ketocal-4-1",
        name: "Суміш суха KetoCal 4:1",
        filters: {
          name: "Епілепсія, Кетогенна дієта",
          type: "epi KDiet",
          age: ["12+", "18+", "36+"],

        },
        img: "28.1.png",
        show: true,
        gtm: "btn-calc-day-norm-nutrilon-dairy",
        hide: false,
        to: "calc1",
        btn1: false,
        btn2: true,
        btn3: false,
      },

      {
        id: 21,
        name: "Суміш рідка Nutrilon Передчасний догляд",
        filters: {
          name: "Передчасно народжені",
          type: "prematurely",
          age: ["0-6", "6-12"],
        },
        img: "23.png",
        show: true,
        gtm: "btn-calc-day-norm-nutrilon-dairy",
        hide: false,
        to: "calc1",
        btn1: false,
        btn2: true,
        btn3: false,
      },
      {
        id: "preterm-powder-Midas",
        name: "Суміш суха Nutrilon Передчасний догляд",
        filters: {
          name: "Передчасно народжені",
          type: "prematurely",
          age: ["0-6", "6-12"],

        },
        img: "24.png",
        show: true,
        gtm: "btn-calc-day-norm-nutrilon-dairy",
        hide: false,
        to: "calc1",
        btn1: false,
        btn2: true,
        btn3: true,
      },
      {
        id: 23,
        name: "Суміш суха Nutrilon Передчасний догляд вдома",
        filters: {
          name: "Передчасно народжені",
          type: "prematurely",
          age: ["0-6", "6-12", "12+", "18+"],

        },
        img: "25.png",
        show: true,
        gtm: "btn-calc-day-norm-nutrilon-dairy",
        hide: false,
        to: "calc1",
        btn1: false,
        btn2: true,
        btn3: true,
      },
      {
        id: 24,
        name: "Nutrilon Білкова добавка",
        filters: {
          name: "Передчасно народжені",
          type: "prematurely",
          age: ["0-6", "6-12"],

        },
        img: "26.png",
        show: true,
        gtm: "btn-calc-day-norm-nutrilon-dairy",
        hide: false,
        to: "calc1",
        btn1: false,
        btn2: true,
        btn3: true,
      }
    ],

    filterItemDrop1: [
      {
        name: "Всі варіанти",
        type: "all",
      },
      {
        name: "Здорові діти",
        type: "healthy",
      },
      {
        name: "Проблеми з травленням",
        type: "digestion",
      },
      {
        name: "Харчова алергія",
        type: "allergy",
      },
      {
        name: "Передчасно народжені",
        type: "prematurely",
      },
      {
        name: "Недостатність харчування",
        type: "lowFood",
      },
      {
        name: "Кетогенна дієта",
        type: "KDiet",
      },
      {
        name: "Епілепсія",
        type: "epi",
      },
      // {
      //   name: "Кетогенна дієта",
      //   type: "ketDiet",
      // },
    ],

    filterItemDrop2: [

      {
        name: "до 6 місяців",
        type: "0-6",
      },
      {
        name: "від 6 до 12 місяців",
        type: "6-12",
      },
      {
        name: "від 12 місяців",
        type: "12+",
      },
      {
        name: "від 18 місяців",
        type: "18+",
      },
      {
        name: "від 36 місяців",
        type: "36+",
      },

    ],

    filter1Chose: "Потреби",
    filter2Chose: "Вік",

    filter1Type: "",
    filter2Type: "",


  }),

  beforeMount() {
    this.allProd = this.allProdItems.length;
  },

  methods: {
    declOfNum(number, titles) {
      let cases = [2, 0, 1, 1, 1, 2];
      return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
    },

    openDropListNeed() {


      if (this.dropList1 === false && this.dropList2 !== true) {

        this.dropList1 = true

      } else if (this.dropList1 === false && this.dropList2 === true) {

        setTimeout(() => {
          this.dropList1 = true
        }, 500);

        this.dropList2 = false

      } else {

        this.dropList1 = false
      }
    },

    openDropListAge() {

      if (this.dropList2 === false && this.dropList1 !== true) {
        this.dropList2 = true
      } else {
        this.dropList2 = false
      }
    },

    choseFilter1(value, name) {

      this.filter1Chose = name;
      this.dropList1 = false;
      this.filter1Type = value;


      this.filterFunction()
    },

    choseFilter2(value, name) {

      this.filter2Chose = name;
      this.dropList2 = false;
      this.filter2Type = value;

      this.filterFunction()
    },

    filterFunction() {
      this.allProd = 0;

      this.allProdItems.forEach((el) => {
        el.show = true;

        if (!el.filters.type.includes(this.filter1Type) && this.filter1Chose !== "Потреби" && this.filter1Type !== "all") {
          el.show = false;
        } else if (this.filter1Type === "all") {
          el.show = true;
        }

        if (!el.filters.age.includes(this.filter2Type) && this.filter2Chose !== "Вік") {
          el.show = false;
        }

        if (el.show === true) {
          this.allProd++;
        }

      });
    },

    goToNextStage(prodNumber, to) {
      sessionStorage.setItem("production", prodNumber);

      console.log(to)
      if (to === 'calc1') {
        this.$router.push({name: 'prod', params: {id: prodNumber}})
      }
      if (to === 'calc2') {
        this.$router.push({name: 'prod-energy', params: {id: prodNumber}})
      }
    },

    goToNextStageOverview(prodNumber, to) {
      sessionStorage.setItem("production", prodNumber);
      sessionStorage.setItem("isDetaisOpen", "open");

      if (to === 'calc1') {
        this.$router.push({name: 'prod', params: {id: prodNumber}})
      }
      if (to === 'calc2') {
        this.$router.push({name: 'prod-energy', params: {id: prodNumber}})
      }
    }
  }
}
</script>

<style lang="scss">
@import "../style/vars";

.calc {
  position: relative;

  .download-info {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 30px;

    .right {
      display: flex;
      flex-direction: column;
      margin-top: 10px;

      .reg-text {
        font-size: 14px;
        font-family: $fontR;
        color: #1E3B89;
        margin-bottom: 15px;
      }

      .btn {
        width: 185px;
        height: 40px;
        border-radius: 4px;
        background-color: #00ACE6;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        p {

          text-transform: uppercase;
          letter-spacing: 2px;
          font-family: $fontB;
          color: white;
          font-size: 15px;

          span {
            margin-right: 5px;
          }
        }
      }
    }
  }

  .head-links {
    background-color: rgba(255, 255, 255, 0.49);
    width: 100%;
    padding: 10px 15px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .back-links {
      color: #7392B1;
      font-family: $fontR;
      font-size: 11px;
    }

    span {
      padding: 0 5px;
      color: #7392B1;
      font-family: $fontR;
      font-size: 11px;
    }

    .now-page {
      color: #00ACE6;
      font-family: $fontSB;
      font-size: 11px;
    }
  }

  .content-wrapper {
    padding: 0 15px;

    .filter-block {
      width: 100%;
      background-color: white;
      margin-top: 10px;
      margin-bottom: 30px;

      .h2-title {
        text-align: center;
        padding: 11px 0;
        color: #9AB9D8;
        font-family: $fontB;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 2px;
      }


      .drop-item1, .drop-item2 {
        border-top: 1px solid #DAE3ED;
        padding: 15px;
        position: relative;
        display: flex;
        justify-content: center;
        flex-direction: column;
        background-color: white;
        transition: background-color .5s ease;

        .visible-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          cursor: pointer;

          span {
            display: flex;
            font-size: 15px;
            font-family: $fontR;
            color: #1E3B89;
          }

          .icon {
            display: flex;
            margin-right: 20px;
          }

          .arrow {
            transition: transform .2s ease;
            display: flex;
            margin-left: auto;
            cursor: pointer;
          }
        }

        .hide-item {
          padding: 0 15px;
          z-index: 10;
          position: absolute;
          top: 57px;
          left: 0;
          width: 100%;
          max-height: 0;
          background: #E0ECF7;
          overflow: hidden;
          transition: padding .5s ease, max-height .5s ease;


          p {
            padding: 10px 0;
            color: #1E3B89;
            font-size: 15px;
            font-family: $fontL;
            cursor: pointer;

            &.active, &:hover {
              font-family: $fontR;
            }
          }
        }

        &.active {
          background: #E0ECF7;

          .hide-item {
            border-top: 1px solid #DAE3ED;
            max-height: 500px;
            padding: 10px 15px;
          }

          .arrow {
            transform: rotate(180deg);
          }
        }
      }

    }

    .production-wrapper {
      .count-prod {
        margin-bottom: 30px;
        padding: 0 15px;

        p {
          color: #9AB9D8;
          font-size: 12px;
          font-family: $fontR;
        }
      }

      .item-block {
        background: #FFFFFF;
        border-radius: 4px;
        padding: 15px;
        margin-bottom: 20px;

        .h2-title {
          color: #1E3B89;
          font-size: 15px;
          font-family: $fontSB;
          margin-bottom: 10px;
        }

        .type {
          color: rgba(30, 59, 137, 0.7);
          font-size: 12px;
          font-family: $fontL;
        }

        img {
          display: block;
          margin: 30px auto;
          max-width: 130px;
          max-height: 220px;
          width: 100%;
          height: auto;
          object-fit: contain;
        }

        .calc-btn {
          width: 100%;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #00ACE6;
          border-radius: 4px;
          cursor: pointer;

          p {
            letter-spacing: 0.05em;
            text-transform: uppercase;

            color: #FFFFFF;
            font-size: 14px;
            font-family: $fontB;
          }
        }

        .sampling-btn {
          margin-top: 10px;
          border: 1px solid #00ACE6;
          background-color: #fff;
          cursor: pointer;

          p {
            letter-spacing: 0.05em;
            text-transform: uppercase;

            color: #00ACE6;
            font-size: 14px;
            font-family: $fontB;
          }
        }
      }
    }
  }

}

</style>
